const menuNav = () => {
    const menuBtn = document.querySelector('.header__burger'),
    menuTop = document.querySelector('.header__nav'),
    headerItems = document.querySelectorAll('.header__item')
    let menuOpen = false;
    menuBtn.addEventListener('click', () => {
        if(!menuOpen) {
            menuBtn.classList.add('open');
            menuTop.classList.add('active');
            menuOpen = true;
            html.style.overflow = 'hidden';
            
            
        } else {
            menuBtn.classList.remove('open');
            menuTop.classList.remove('active');
            menuOpen = false;
            html.style.overflowY = "auto";
        }
    });
    headerItems.forEach(item => {
        item.addEventListener('click', () => {
            menuBtn.classList.remove('open');
            menuTop.classList.remove('active');
            menuOpen = false;
            html.style.overflowY = "auto"; 
        })
    })
} 
const heroSlider = () => {
    const swiper = new Swiper('.hero__left', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    });
}
const workSlider = () => {
    const swiper = new Swiper('.slider__container', {
        loop: true,
        slidesPerView: 3.2,
        spaceBetween: 109,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 30
            },
            576: {
                slidesPerView: 1.5,
                spaceBetween: 50
            },
            992: {
                slidesPerView: 2.5,
            },
            1200: {
                spaceBetween: 60
            },
            1360: {
              spaceBetween: 109
            }
          }
    });
}

// SERVICES
const seeMore = () => {
    const servicesItems = document.querySelectorAll('.services__row');
    const seeMoreBnt = document.querySelector('.see__more .btn')
    if(!servicesItems.length || !seeMoreBnt) return
    seeMoreBnt.addEventListener('click', () => {
        if(seeMoreBnt.classList.contains('opened')) {
            seeMoreBnt.classList.remove('opened')
            seeMoreBnt.textContent = 'zobacz więcej'
            for(let i = 1; i < servicesItems.length; i++) {
                servicesItems[i].style.display = 'none';
            }
            return
        }
        seeMoreBnt.classList.add('opened')
        seeMoreBnt.textContent = 'zobacz mniej'
        for(let i = 1; i < servicesItems.length; i++) {
            servicesItems[i].style.display = 'flex';
        }

    })


}

// FAQ
const faq = () => {
    const faqTrigger = document.querySelectorAll('.faq__trigger');
    if(!faqTrigger.length) return
    faqTrigger.forEach(trigger => {
        trigger.addEventListener('click', (e) => {
            const mainItem = trigger.closest('.faq__item');
            const content = mainItem.querySelector('.faq__content');
            if(content.classList.contains('active')) {
                content.classList.remove('active')
                trigger.textContent = 'rozwiń';
                content.style.maxHeight = '0px';  
            } else {
                content.classList.add('active');
                trigger.textContent = 'zwiń';
                content.style.maxHeight = `${content.scrollHeight}px`;
            }
            
        })
    })
}

// FORM
const formCheckbox = () => {
    const checkbox = document.querySelector('input[type="checkbox"]');
    const btn = document.querySelector('input[type="submit"')
    if(!checkbox || !btn) return
    checkbox.addEventListener('change', () => btn.disabled = checkbox.checked ? false : true)
}

// ANIMATION
const animation = () => {
    const animItems = document.querySelectorAll('[data-animate]');
    const itemsAnimation = (array, threshold) => {
        if(array.length) {
            const observerText = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate')
                        observer.unobserve(entry.target)
                    }
                })
            }, { 
                threshold: threshold,
            })
            array.forEach(item => observerText.observe(item))
        }
    }
    itemsAnimation(animItems, .5);


}

document.addEventListener('DOMContentLoaded', () => {
    menuNav();
    heroSlider();
    workSlider();
    seeMore();
    faq();
    formCheckbox();
    animation();
})
